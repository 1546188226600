<template>
  <div class="contact">
    <div class="row justify-content-start contact-bg">
      <div class="col-1"></div>
      <div class="col-10">
        <p
          style="float:left; font-weight: bold; color:#2E3238; font-size:25px; margin:30px 0px 20px 0px;"
        >联系我们</p>
        <p style="float:left; text-align:left; font-size:14px; color:#2E3238;">
          Ai·Li高性能漆面膜以
          <span style="font-weight:bold;">"让每位车主都能享受科技带来的驾乘乐趣”</span>为愿景，立足国内车主的用车环境和使用需求，专注高品质汽车保护膜的生产销售。
        </p>
        <p style="float:left; text-align:left; font-size:14px; margin-top:15px;">
          <i class="iconfont icon-dizhi"></i>
          <br />
          <span style="font-weight: bold;">黑龙江省哈尔滨市南岗区477号奔马汽配城负一层F30号</span>
          <br />
          <i class="iconfont icon-dianhua"></i>
          <br />
          <span style="font-weight: bold;">180 4505 7555</span>
        </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row justify-content-start" style="margin:30px 0px 20px 0px;">
      <div class="col-1"></div>
      <div class="col-10">
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          v-show="successVisibal"
        >
          留言成功
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          v-show="committedVisible"
        >您已留言，请您耐心等待我们主动联系您！</div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">姓名</span>
          </div>
          <input
            type="text"
            v-model="name"
            class="form-control"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">手机</span>
          </div>
          <input
            type="text"
            v-model="phone"
            class="form-control"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">留言</span>
          </div>
          <textarea v-model="content" class="form-control" aria-label="With textarea"></textarea>
        </div>
        <button class="button" @click="submit">确认留言</button>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import data from '../../config/index'
export default {
  components: {},
  data() {
    return {
      imageUrl: data.imageUrl,
      name: null,
      phone: null,
      content: null,
      // 留言成功
      successVisibal: false,
      // 未提交
      uncommitted: true,
      // 已提交
      committedVisible: false
    }
  },
  methods: {
    submit() {
      if (this.uncommitted) {
        if (this.phone.length !== 11) {
          return
        }
        return new Promise((resolve, reject) => {
          axios
            .post('/car/message/baseSave', {
              // .post('http://localhost:9091/car/message/baseSave', {
              name: this.name,
              phone: this.phone,
              content: this.content
            })
            .then(res => {
              this.successVisibal = true
              this.uncommitted = false
              resolve(res)
            })
            .catch(error => {
              reject(error)
            })
        })
      } else {
        this.successVisibal = false
        this.committedVisible = true
      }
    }
  }
}
</script>
<style scoped>
@import '../../assets/css/contact.css';
@import '../../assets/font/iconfont.css';
</style>